import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { getBuyerId } from 'dibs-cookie-jar';
import { RecommendedItemsLazy } from 'dibs-recommended-for-you/exports/RecommendedItemsLazy';
import {
    trackModuleLocation,
    trackModule,
    trackItemClick,
    trackItemImpression,
    trackInitialDisplay,
    trackArrowClick,
    trackFavoriting,
} from 'dibs-recommended-for-you/exports/tracking';
import { stepInteractionConstants } from 'dibs-tracking';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { addToEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';

import { SbSharedRecommendedItems_viewer$key } from './__generated__/SbSharedRecommendedItems_viewer.graphql';

const { STEP_INTERACTION_ITEM_ADDED, STEP_INTERACTION_ITEM_REMOVED } = stepInteractionConstants;

type Props = {
    viewer: SbSharedRecommendedItems_viewer$key;
};

const viewerFragment = graphql`
    fragment SbSharedRecommendedItems_viewer on Viewer {
        ...RecommendedItemsCarousel_viewer @arguments(returnAmount: 12)
    }
`;

export const SbSharedRecommendedItems: FC<Props> = ({ viewer: viewerRef }) => {
    const viewer = useFragment(viewerFragment, viewerRef);

    const { isMobile } = useServerVarsContext();
    const userId = typeof document !== 'undefined' ? getBuyerId(document.cookie) : '';
    return (
        <RecommendedItemsLazy
            viewer={viewer}
            userId={userId}
            isMobile={!!isMobile}
            itemsPerPage={6}
            showTitle={false}
            onItemClick={({ item, index }) => {
                trackItemClick({ item, index, variant: 'search/browse page' });
                trackModuleLocation({
                    action: 'clicked search/browse page module',
                });
                trackModule({
                    action: 'search/browse page click',
                    label: 'Recommended For You',
                });
            }}
            onItemContentVisible={props => {
                trackItemImpression({ ...props, variant: 'search/browse page' });
            }}
            onCarouselContentVisible={props => {
                trackInitialDisplay(props);
            }}
            onArrowClick={props => {
                trackArrowClick(props);
            }}
            onFavorited={({ itemId }) => {
                trackFavoriting({
                    action: 'item added',
                    itemId,
                    label: 'search/browse page - recommended for you',
                    step_interaction_name: STEP_INTERACTION_ITEM_ADDED,
                });
                addToEngagedItems(itemId);
            }}
            onUnFavorited={({ itemId }) => {
                trackFavoriting({
                    action: 'item removed',
                    itemId,
                    label: 'search/browse page - recommended for you',
                    step_interaction_name: STEP_INTERACTION_ITEM_REMOVED,
                });
            }}
        />
    );
};
