/**
 * @generated SignedSource<<bfd57bb14fd9baec887513dfd772780c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespSearchResultContainer_auctionsItemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedAuctionItemsBanner_auctionsItemSearch">;
  readonly " $fragmentType": "SbRespSearchResultContainer_auctionsItemSearch";
};
export type SbRespSearchResultContainer_auctionsItemSearch$key = {
  readonly " $data"?: SbRespSearchResultContainer_auctionsItemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespSearchResultContainer_auctionsItemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespSearchResultContainer_auctionsItemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedAuctionItemsBanner_auctionsItemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "714e8fedc9f1bb9a2794c1234c8560aa";

export default node;
