/**
 * @generated SignedSource<<d7e36cb6aadf6cbea8110be2509cc775>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespMainContent_auctionsItemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespSearchResultContainer_auctionsItemSearch">;
  readonly " $fragmentType": "SbRespMainContent_auctionsItemSearch";
};
export type SbRespMainContent_auctionsItemSearch$key = {
  readonly " $data"?: SbRespMainContent_auctionsItemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespMainContent_auctionsItemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespMainContent_auctionsItemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespSearchResultContainer_auctionsItemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "e1f8312744bf24b5b29f54dc6494f661";

export default node;
