/**
 * @generated SignedSource<<fb3f0ae506547318c9249ea7fd549434>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedAuctionItemsBanner_auctionsItemSearch$data = {
  readonly totalResults: number | null;
  readonly " $fragmentType": "SbSharedAuctionItemsBanner_auctionsItemSearch";
};
export type SbSharedAuctionItemsBanner_auctionsItemSearch$key = {
  readonly " $data"?: SbSharedAuctionItemsBanner_auctionsItemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedAuctionItemsBanner_auctionsItemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedAuctionItemsBanner_auctionsItemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalResults",
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "fe41e3d0465c94e69c28f85cade7f639";

export default node;
