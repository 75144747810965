import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';

import styles from './RecommendedItemsWrapper.scss';

type Props = {
    children: ReactNode;
};

export const RecommendedItemsWrapper: FC<Props> = ({ children }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <HeadingLevel>
                    {Heading => (
                        <Heading className={styles.title}>
                            <FormattedMessage
                                id="RecommendedItemsCarousel.title"
                                defaultMessage="Recommended For You"
                            />
                        </Heading>
                    )}
                </HeadingLevel>
            </div>
            {children}
        </div>
    );
};
