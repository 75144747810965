import { FC, useEffect, useMemo } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay';
import { Carousel } from 'dibs-carousel';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import { getLocalHistoryItemIds } from 'dibs-recent-history/exports/getLocalHistoryItemIds';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { RecommendedItemsWrapper } from '../RecommendedItemsWrapper/RecommendedItemsWrapper';
import { RecommendedItem, Item } from '../RecommendedItem/RecommendedItem';
import { ItemClickProps, ItemContentVisibleProps } from '../types';

import { RecommendedItemsCarousel_viewer$key } from './__generated__/RecommendedItemsCarousel_viewer.graphql';
import { RecommendedItemsCarouselViewerRefetchQuery } from './__generated__/RecommendedItemsCarouselViewerRefetchQuery.graphql';

import styles from './RecommendedItemsCarousel.scss';

export type Props = {
    viewer: RecommendedItemsCarousel_viewer$key;
    isMobile: boolean;
    userId: string;
    itemsPerPage?: number;
    titleClassName?: string;
    showTitle?: boolean;
    onItemClick?: (props: ItemClickProps) => void;
    onItemContentVisible?: (props: ItemContentVisibleProps) => void;
    onCarouselContentVisible?: ({ itemsReturned }: { itemsReturned: number }) => void;
    onArrowClick?: ({ direction }: { direction: string }) => void;
    onFavorited?: ({ itemId }: { itemId: string | null }) => void;
    onUnFavorited?: ({ itemId }: { itemId: string | null }) => void;
};

const viewerFragment = graphql`
    fragment RecommendedItemsCarousel_viewer on Viewer
    @refetchable(queryName: "RecommendedItemsCarouselViewerRefetchQuery")
    @argumentDefinitions(
        recentlyViewedIds: { type: "[String]", defaultValue: [] }
        userIds: { type: "[String]", defaultValue: [] }
        loadPortfolioData: { type: "Boolean", defaultValue: false }
        returnAmount: { type: "Int", defaultValue: 15 }
        pageSize: { type: "Int", defaultValue: 18 }
    ) {
        recommendedItems(
            recentlyViewedIds: $recentlyViewedIds
            userIds: $userIds
            pageSize: $pageSize
            returnAmount: $returnAmount
        ) {
            serviceId
            ...RecommendedItem_item
        }
        ...RecommendedItem_viewer
            @arguments(userIds: $userIds, loadPortfolioData: $loadPortfolioData)
    }
`;

const MOBILE_ITEMS_PER_PAGE = 2.5;

export const RecommendedItemsCarousel: FC<Props> = ({
    viewer: viewerRef,
    itemsPerPage = 5,
    isMobile,
    userId,
    showTitle,
    onItemClick,
    onItemContentVisible,
    onCarouselContentVisible = () => {},
    onArrowClick = () => {},
    onFavorited,
    onUnFavorited,
}) => {
    const [viewer, refetch] = useRefetchableFragment<
        RecommendedItemsCarouselViewerRefetchQuery,
        RecommendedItemsCarousel_viewer$key
    >(viewerFragment, viewerRef);
    const items = viewer.recommendedItems || [];
    const itemsIds = useMemo(() => items.map(item => item?.serviceId), [items]);

    useEffect(() => {
        if (userId) {
            const localHistoryItemIds = getLocalHistoryItemIds({ isClient: true });
            refetch({
                recentlyViewedIds: localHistoryItemIds,
                userIds: [userId],
                loadPortfolioData: true,
            });
        }
    }, [refetch, userId]);

    const renderItem: FC<{ item: Item; index: number }> = ({ item, index }) => (
        <RecommendedItem
            key={index}
            viewer={viewer}
            item={item}
            itemsIds={itemsIds}
            userId={userId}
            index={index}
            isMobile={isMobile}
            itemsPerPage={itemsPerPage}
            showTitle={showTitle}
            onItemClick={onItemClick}
            onItemContentVisible={onItemContentVisible}
            onFavorited={onFavorited}
            onUnFavorited={onUnFavorited}
        />
    );

    if (!items.length) {
        return null;
    }

    return (
        <VisibilityTracker
            onVisibilityChange={() => onCarouselContentVisible({ itemsReturned: items.length })}
        >
            <RecommendedItemsWrapper>
                {isMobile ? (
                    <Swiper
                        itemsPerPage={MOBILE_ITEMS_PER_PAGE}
                        classNames={{
                            wrapper: styles.swiperWrapper,
                            item: styles.swiperItem,
                        }}
                    >
                        {items.map((item, index) => renderItem({ item, index }))}
                    </Swiper>
                ) : (
                    <Carousel
                        slideCarouselItems
                        step={itemsPerPage}
                        itemsPerPage={itemsPerPage}
                        totalItems={items.length}
                        renderItem={({ index }: { index: number }) =>
                            renderItem({ item: items[index], index })
                        }
                        onLeftArrowClick={() => onArrowClick({ direction: 'left' })}
                        onRightArrowClick={() => onArrowClick({ direction: 'right' })}
                    />
                )}
            </RecommendedItemsWrapper>
        </VisibilityTracker>
    );
};
