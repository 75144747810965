import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { trackSearchWithinResults } from '../../utils/tracking/searchBrowse/filterTracking';
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';
import { SbSharedRefineMenuFilters } from '../SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilters';
import { SbSharedRefineMenuDisplayPrefsSelect } from '../SbSharedRefineMenu/SbSharedRefineMenuDisplayPrefsSelect/SbSharedRefineMenuDisplayPrefsSelect';
import { SbSharedRefineMenuSearchWithin } from '../SbSharedRefineMenu/SbSharedRefineMenuSearchWithin/SbSharedRefineMenuSearchWithin';
import { sbRespRefineMenuFiltersMap } from './SbRespRefineMenuFilters/sbRespRefineMenuFiltersMap';

const SbRespRefineMenuComponent = ({
    viewer,
    user,
    filters,
    isClient,
    currency,
    itemSearch,
    uriRef,
}) => {
    return (
        <>
            <SbSharedRefineMenuSearchWithin
                horizontalSpacing="small"
                onSubmit={trackSearchWithinResults}
                itemSearch={itemSearch}
            />
            <SbSharedRefineMenuFilters
                user={user}
                uriRef={uriRef}
                viewer={viewer}
                filters={filters}
                currency={currency}
                isClient={isClient}
                itemSearch={itemSearch}
                filterMap={sbRespRefineMenuFiltersMap}
                initiallyExpanded={filterName =>
                    filterName.includes('category') ||
                    filterName.includes('price') ||
                    filterName.includes('item_type') ||
                    filterName.includes('jewelry-type') ||
                    filterName.includes('auction')
                }
            />
            {itemSearch?.pageType !== pageTypes.DESIGN && (
                <SbSharedRefineMenuDisplayPrefsSelect
                    user={user}
                    filters={filters}
                    itemSearch={itemSearch}
                    isClient={isClient}
                />
            )}
        </>
    );
};

SbRespRefineMenuComponent.propTypes = {
    isClient: PropTypes.bool,
    viewer: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    itemSearch: PropTypes.object.isRequired,
    user: PropTypes.object,
    uriRef: PropTypes.string,
};

export const SbRespRefineMenu = createFragmentContainer(SbRespRefineMenuComponent, {
    viewer: graphql`
        fragment SbRespRefineMenu_viewer on Viewer {
            ...SbSharedRefineMenuFilters_viewer
        }
    `,
    itemSearch: graphql`
        fragment SbRespRefineMenu_itemSearch on ItemSearchQueryConnection {
            topCategoryL1
            pageType
            ...SbSharedRefineMenuFilters_itemSearch
            ...SbSharedRefineMenuDisplayPrefsSelect_itemSearch
            ...SbSharedRefineMenuSearchWithin_itemSearch
        }
    `,
    filters: graphql`
        fragment SbRespRefineMenu_filters on SearchBrowseFilter @relay(plural: true) {
            ...SbSharedRefineMenuFilters_filters
            ...SbSharedRefineMenuDisplayPrefsSelect_filters
        }
    `,
    user: graphql`
        fragment SbRespRefineMenu_user on User {
            ...SbSharedRefineMenuFilters_user
            ...SbSharedRefineMenuDisplayPrefsSelect_user
        }
    `,
});
