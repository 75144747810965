/**
 * @generated SignedSource<<bc343c5a6e6641b8e29cf3ead02550cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespAppliedFilters_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly canBeDismissed: boolean | null;
    readonly localizedFilterName: string | null;
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly displayName: string | null;
      readonly hexCode: string | null;
      readonly linkReference: string | null;
      readonly properties: {
        readonly mm: string | null;
        readonly us: string | null;
      } | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly clearAppliedFiltersLinkReference: string | null;
  readonly filters: ReadonlyArray<{
    readonly localizedFilterName: string | null;
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly displayName: string | null;
      readonly linkReference: string | null;
      readonly properties: {
        readonly mm: string | null;
        readonly us: string | null;
      } | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly pageType: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"useMeasurementUnit_itemSearch" | "useRingMeasurementType_itemSearch">;
  readonly " $fragmentType": "SbRespAppliedFilters_itemSearch";
};
export type SbRespAppliedFilters_itemSearch$key = {
  readonly " $data"?: SbRespAppliedFilters_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespAppliedFilters_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SearchBrowseParsedParam",
    "kind": "LinkedField",
    "name": "appliedFilters",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "SearchBrowseParsedParamValue",
        "kind": "LinkedField",
        "name": "values",
        "plural": true,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedFilterName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkReference",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "FilterPropertiesType",
  "kind": "LinkedField",
  "name": "properties",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "us",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespAppliedFilters_itemSearch",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useMeasurementUnit_itemSearch",
      "selections": (v2/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useRingMeasurementType_itemSearch",
      "selections": (v2/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clearAppliedFiltersLinkReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilter",
      "kind": "LinkedField",
      "name": "filters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseFilterValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            (v1/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBeDismissed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v5/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hexCode",
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "30be9f2d5fdc1f3fcedc7a12c31a3de9";

export default node;
