import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

// styles
import classNames from 'classnames';
import dibsCss from 'dibs-css';

import { SbRespBrowseHeader_itemSearch$key } from './__generated__/SbRespBrowseHeader_itemSearch.graphql';

// constants
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';
const { BROWSE } = pageTypes;

const itemSearchFragment = graphql`
    fragment SbRespBrowseHeader_itemSearch on ItemSearchQueryConnection {
        pageType
        meta {
            header
        }
    }
`;

type Props = {
    itemSearch: SbRespBrowseHeader_itemSearch$key;
};

export const SbRespBrowseHeader: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const { isNewNetPrice15Filter } = useServerVarsContext();
    const header = itemSearch?.meta?.header;
    const pageType = itemSearch?.pageType;

    if (pageType !== BROWSE || !header || isNewNetPrice15Filter) {
        return null;
    }
    return (
        <div className={classNames(dibsCss.sassyFontHeaderSmallItalic, dibsCss.mbSmall)}>
            <FormattedMessage
                id="sb.SbRespBrowseHeader.header"
                defaultMessage="{header} For Sale"
                values={{ header }}
            />
        </div>
    );
};
