import { FC, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';
import { trackEvent } from 'dibs-tracking';
import VisibilityTracker from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { Button } from 'dibs-elements/exports/Button';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { useSbSelector } from '../../reducers/useSbSelector';
import {
    checkRewardedSeller,
    checkTradeUserUnderTopLevelLoyaltyTiers,
} from 'dibs-buyer-layout/exports/sellerBrandingHelpers';
import { isSellerBrandingRemovalTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';

import dibsCss from 'dibs-css';
import styles from './SbSharedAuctionItemsBanner.scss';

import { SbSharedAuctionItemsBanner_auctionsItemSearch$key } from './__generated__/SbSharedAuctionItemsBanner_auctionsItemSearch.graphql';
import { SbSharedAuctionItemsBanner_seller$key } from './__generated__/SbSharedAuctionItemsBanner_seller.graphql';
import { SbSharedAuctionItemsBanner_user$key } from './__generated__/SbSharedAuctionItemsBanner_user.graphql';

const auctionsItemSearchFragment = graphql`
    fragment SbSharedAuctionItemsBanner_auctionsItemSearch on ItemSearchQueryConnection {
        totalResults
    }
`;

const sellerFragment = graphql`
    fragment SbSharedAuctionItemsBanner_seller on Seller {
        serviceId
        sellerProfile {
            company
        }
        ...sellerBrandingHelpers_seller
    }
`;

const userFragment = graphql`
    fragment SbSharedAuctionItemsBanner_user on User {
        ...sellerBrandingHelpers_user
    }
`;

type Props = {
    auctionsItemSearch: SbSharedAuctionItemsBanner_auctionsItemSearch$key;
    seller: SbSharedAuctionItemsBanner_seller$key | null;
    user: SbSharedAuctionItemsBanner_user$key;
    className?: string;
};

const SbSharedAuctionItemsBanner: FC<Props> = ({
    auctionsItemSearch: auctionsItemSearchRef,
    seller: sellerRef,
    user: userRef,
    className,
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const isClient = useSbSelector(state => state.relayVariables.variables.isClient);

    const auctionsItemSearch = useFragment(auctionsItemSearchFragment, auctionsItemSearchRef);
    const seller = useFragment(sellerFragment, sellerRef);
    const user = useFragment(userFragment, userRef);

    const totalAuctions = auctionsItemSearch?.totalResults || 0;
    const sellerPk = seller?.serviceId;
    const shouldShow = totalAuctions >= 6 && sellerPk;

    if (!shouldShow) {
        return null;
    }

    const shouldShowSellerName =
        isClient &&
        (!isSellerBrandingRemovalTestVariant() ||
            checkRewardedSeller(seller) ||
            checkTradeUserUnderTopLevelLoyaltyTiers(user || null));

    const company = seller.sellerProfile?.company;
    const ctaLink = `/auctions/?sellerPk=${sellerPk}`;
    const sellerMessageType = shouldShowSellerName ? 'show' : 'hide';

    return (
        <div
            className={classnames(
                dibsCss.flex,
                dibsCss.flexCol,
                dibsCss.gapSmall,
                dibsCss.itemsCenter,
                dibsCss.textCenter,
                dibsCss.bgBuyertertiary,
                dibsCss.pMedium,
                className
            )}
            ref={containerRef}
        >
            <VisibilityTracker
                elementRef={containerRef}
                onVisibilityChange={({ isVisible }) =>
                    isVisible &&
                    trackEvent({
                        event: 'e_ga_event',
                        action: 'seller auction listings displayed',
                        category: 'promo interaction',
                        label: sellerPk,
                        noninteraction: 1,
                    })
                }
            />
            <HeadingLevel>
                {Heading => (
                    <Heading className={classnames(dibsCss.sassyFontHeader, dibsCss.m0)}>
                        <FormattedMessage
                            id="sb.SbSharedAuctionItemsBanner.header"
                            defaultMessage="<italic>{sellerMessageType, select,
                                hide {Vetted Professional Seller}
                                other {{company}}
                            }</italic> at Auction"
                            values={{
                                italic: content => (
                                    <span
                                        className={classnames(
                                            dibsCss.sassyFontHeaderTypeItalic,
                                            !isClient && [styles.shimmer, dibsCss.textTransparent]
                                        )}
                                    >
                                        {content}
                                    </span>
                                ),
                                sellerMessageType,
                                company,
                            }}
                        />
                    </Heading>
                )}
            </HeadingLevel>
            <p className={dibsCss.sassyFontBody}>
                <FormattedMessage
                    id="sb.SbSharedAuctionItemsBanner.content"
                    defaultMessage="<b>Looking for a rare item at a great deal?</b> Browse <shimmer>{sellerMessageType, select,
                        hide {seller's}
                        other {{company}}}</shimmer> auction items."
                    values={{
                        b: content => (
                            <span className={dibsCss.sassyFontBodyTypeHeavy}>{content}</span>
                        ),
                        shimmer: content => (
                            <span
                                className={classnames(
                                    !isClient && [styles.shimmer, dibsCss.textTransparent]
                                )}
                            >
                                {content}
                            </span>
                        ),
                        sellerMessageType,
                        company,
                    }}
                />
            </p>
            <div>
                <Button
                    type="secondary"
                    size="medium"
                    href={ctaLink}
                    onClick={() =>
                        trackEvent({
                            event: 'e_ga_event',
                            action: 'seller auction listings click',
                            category: 'promo interaction',
                            label: sellerPk,
                            noninteraction: 0,
                        })
                    }
                >
                    <FormattedMessage
                        id="sb.SbSharedAuctionItemsBanner.cta"
                        defaultMessage="Browse auction items"
                    />
                </Button>
            </div>
        </div>
    );
};

export default SbSharedAuctionItemsBanner;
