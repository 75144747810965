/**
 * @generated SignedSource<<d60ef71d71139850f6d3abaf0d314f31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedAuctionItemsBanner_seller$data = {
  readonly sellerProfile: {
    readonly company: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"sellerBrandingHelpers_seller">;
  readonly " $fragmentType": "SbSharedAuctionItemsBanner_seller";
};
export type SbSharedAuctionItemsBanner_seller$key = {
  readonly " $data"?: SbSharedAuctionItemsBanner_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedAuctionItemsBanner_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedAuctionItemsBanner_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerProfile",
      "kind": "LinkedField",
      "name": "sellerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "sellerBrandingHelpers_seller",
      "selections": [
        {
          "alias": "isRewarded",
          "args": [
            {
              "kind": "Literal",
              "name": "listNames",
              "value": [
                "SELLER_REWARDS_1",
                "SELLER_REWARDS_2"
              ]
            }
          ],
          "kind": "ScalarField",
          "name": "isAnchorListsMember",
          "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "7257015d0a8df25117d67b75432dfecd";

export default node;
