import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'dibs-react-intl';

import { Switch } from 'dibs-elements/exports/Switch';

import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import { updateUriRef } from '../../actions/filterActions';
import { decode64 } from '../../utils/decode';
import { getFilterValue } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { NET_PRICE_TRADE_DISCOUNT } from '../SbSharedRefineMenu/sbSharedRefineMenuConstants';

import dibsCss from 'dibs-css';
import classNames from 'classnames';

import { SbSharedNetPriceFilterSwitch_itemSearch$key } from './__generated__/SbSharedNetPriceFilterSwitch_itemSearch.graphql';

const itemSearchFragment = graphql`
    fragment SbSharedNetPriceFilterSwitch_itemSearch on ItemSearchQueryConnection {
        filters {
            name
            values {
                urlLabel
                linkReference
                count
            }
        }
        appliedFilters {
            name
            values {
                urlLabel
            }
        }
    }
`;

type Props = {
    itemSearch: SbSharedNetPriceFilterSwitch_itemSearch$key;
};

export const SbSharedNetPriceFilterSwitch: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const dispatch = useDispatch();
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const filters = (itemSearch?.filters || []).filter(filterNulls);
    const appliedFilters = (itemSearch?.appliedFilters || []).filter(filterNulls);

    const netTradePriceDiscountFilter = getFilterValue(filters, NET_PRICE_TRADE_DISCOUNT);

    if (!netTradePriceDiscountFilter || !netTradePriceDiscountFilter?.count) {
        return null;
    }

    const appliedFilterValue = getFilterValue(appliedFilters, NET_PRICE_TRADE_DISCOUNT);

    return (
        <Switch
            label={
                <span className={classNames(dibsCss.sassyFontBodyMedium, dibsCss.textNoir)}>
                    <FormattedMessage
                        id="SbSharedNetPriceFilterSwitch.label"
                        defaultMessage="Net Price 15%+"
                    />
                </span>
            }
            checked={netTradePriceDiscountFilter.urlLabel === appliedFilterValue?.urlLabel}
            dataTn="trade-net-price-filter-switch"
            name="trade-net-price-filter-switch"
            onChange={(checked, event) => {
                // If AB test succeeds - should make sense to pass `isDeselected` instead of mocking indexes
                const label = `${NET_PRICE_TRADE_DISCOUNT} ${checked ? '1' : '0'}`;
                dispatch(
                    updateUriRef({
                        filterName: NET_PRICE_TRADE_DISCOUNT,
                        filterValue: netTradePriceDiscountFilter,
                        event,
                        uriRef: decode64(netTradePriceDiscountFilter.linkReference || ''),
                        ga: { label, step_interaction_name: label },
                    })
                );
            }}
        />
    );
};
