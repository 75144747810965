/**
 * @generated SignedSource<<4653eb9249d8e4ac9706df9411363234>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecommendedItem_item$data = {
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ItemTile_item" | "recommendedItemTracking_item">;
  readonly " $fragmentType": "RecommendedItem_item";
};
export type RecommendedItem_item$key = {
  readonly " $data"?: RecommendedItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecommendedItem_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecommendedItem_item",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "recommendedItemTracking_item",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contemporaryTrackingString",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "browseUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categoryCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "showAuctionCountdown",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "showShippingBadge",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemTile_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "50eed38c162811f0df5822f4f1f1a381";

export default node;
